import React from 'react';

import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'reactstrap';

import { receiveProducts } from '@ttstr/actions';
import { useIntl, ContentrArea } from '@ttstr/components';

import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import FilteredMasonry from './FilteredMasonry';

const Viebs: React.FC = () => {
  const { products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Helmet>
        <title>BOBBY FLETCHER & KOLJAH – VIELLEICHT IST ES BESSER SO - Jetzt vorbestellen!</title>
        <body className="viebs-page inverse-navbar" />
      </Helmet>

      <Container>
        <Row className="justify-content-center mt-4">
          <Col md="3" xs="8">
            {/* <img
              src={require('./assets/images/')}
              alt=""
              className="w-100 img-fluid mb-4"
            /> */}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col md="12">
            <h1 className="text-center">
              <span className="h2">BOBBY FLETCHER & KOLJAH</span>
              <br />
              VIELLEICHT IST
              <br />
              ES BESSER SO
              <br />
              <small className="mt-2">
                23.09.2022
                <br />
                MC / CD / LP
              </small>
            </h1>
          </Col>
        </Row>
        <FilteredMasonry
          className="mt-5"
          id="product-listing"
          // type={Maki2020}
          products={products}
          filters="off"
          tagId={5761}
          columnClassName="col-md-6 col-lg-4"
          showSubtitle
          // itemClassName="random-rotation-object"
          // itemImageClassName="scribble-hover-overlay-object"
        />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(Viebs);
