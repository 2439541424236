import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
      </Helmet>
      <Container className="stage mt-5">
        <Link to="/ALGtickets">
          <img
            src={require('./assets/images/header_atg_2024.png')}
            alt="Antilopen GONG - Sommer 2023"
            className="w-100 img-fluid"
          />
        </Link>
      </Container>
      <Container>
        <Row className="mt-3 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-2 ">
            <Link to="/tickets" className="btn btn-lg btn-block btn-outline-success btn-tickets h-100">
              {'Alle Termine'}
            </Link>
          </Col>
          <Col className="mt-2 ">
            <Link to="/ALGtickets" className="btn btn-lg btn-block btn-outline-success btn-tickets h-100 active">
              {'Antilopen Gang '}
              <small>{'LIVE 2024'}</small>
            </Link>
          </Col>
          <Col className="mt-2 ">
            <Link to="/AMRWtickets" className="btn btn-lg btn-block btn-outline-success btn-tickets h-100">
              {'Antilopen Gang '}
              <small>{'AMRW Tour 2025'}</small>
            </Link>
          </Col>
          {/* <Col className="mt-2 ">
            <Link to="/DDGeburtstag" className="btn btn-lg btn-block btn-outline-success btn-tickets h-100">
              {'Danger Dan '}
              <small>{'DAS SCHÖNSTE FEST MEINES LEBENS'}</small>
            </Link>
          </Col> */}
          {/* <Col className="mt-2 ">
            <Link to="/lesereise" className="btn btn-lg btn-block btn-outline-success btn-tickets h-100">
              {'Panik Panzer & Martin Seeliger '}
              <small>{'DER BESTE MENSCH DER WELT - LESEREISE'}</small>
            </Link>
          </Col> */}
        </Row>
      </Container>
      <Container className="pt-3">
        <ContentrArea id="before-listing" />
      </Container>
      <Container className="list-page pb-5">
        {/* <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1> */}

        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : // if no tickets
        tickets.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets.filter((t) => t.tag_ids.includes(3731))}
            filters={['soldOut']}
            type="list"
            showLocation
            showCity
            showTitle
            showSubtitle
          />
        )}
      </Container>
      <Container className="">
        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
