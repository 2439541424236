import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from 'reactstrap';

import { ContentrArea } from '@ttstr/components';

const FullWidthContentrPage = (React.FC = () => {
  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <body className="graydient-background" />
        </Helmet>
        <ContentrArea id="full-width-contentr-page" />
      </Container>
    </article>
  );
});

export default FullWidthContentrPage;
