import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

import { useIntl, LoadingSpinner, Masonry, NativeSlider } from '@ttstr/components';
import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const Highlights: React.FC = () => {
  const { t } = useTranslation();
  const { products, loading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Helmet>
        <body className="amrw highlights-page product-listing-page inverse-navbar" />
      </Helmet>

      <Container>
        <Row className="justify-content-center mt-4">
          <Col md="12" xs="12" className="text-center">
            <h2 className="mb-2">Antilopen Gang</h2>
            <img
              src={require('./assets/images/amrw.svg')}
              alt="Antilopen Gang – „ALLES MUSS REPARIERT WERDEN“ - Jetzt vorbestellen"
              className="w-100 img-fluid mb-1"
            />
          </Col>
          <Col md="12" className="text-center mt-lg-3">
            <h3>Das neue Doppelalbum • VÖ: 13.09.2024</h3>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : // if no products
        products.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="amrw-listing"
            products={products.filter((p) => p.tag_ids.includes(7642))}
            filters="off"
            columnClassName="col-12 col-md-6 col-lg-4"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(Highlights);
