import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Maki2020, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  productFilter?: (p: Article) => any;
};

// const ProductListing: React.FC = () => {
const ProductListing: React.FC<Props> = ({ productFilter = (p) => p }) => {

  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    // <article className="py-5 product-listing">
    //   <Container className="list-page">
    //     <Helmet>
    //       <title>{t(`PRODUCTS.TITLE`)}</title>
    //     </Helmet>

    //     <Masonry
    //       id="product-listing"
    //       rowClassName="card-columns"
    //       columnClassName=""
    //       products={products}
    //       filters="off"
    //       showSubtitle
    //     />
    //     {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
    //   </Container>
    // </article>

    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page inverse-navbar" />
      </Helmet>
      {/* <img src={require('./assets/images/maex_head1.jpg')} alt="MORD AUF EX" className="w-100 h-100 img-fluid mb-4" /> */}
      <Container className="pt-3">
        <ContentrArea id="before-listing" />
      </Container>
      <Container className="list-page py-5">
        {/* <h1 className="text-center mb-0 mt-5">{t(`PRODUCTS.TITLE`)}</h1> */}

        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : // if no products
        products.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            // type={Maki2020}
            // products={products}
            // filters="off"
            products={products.filter(productFilter)}
            columnClassName="col-6 col-md-6 col-lg-4"
            showSubtitle
            showTags
            // itemClassName="random-rotation-object"
            // itemImageClassName="scribble-hover-overlay-object"
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
