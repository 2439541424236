import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

import { useIntl, LoadingSpinner, Masonry, NativeSlider } from '@ttstr/components';
import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const Highlights: React.FC = () => {
  const { t } = useTranslation();
  const { products, loading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Helmet>
        <body className="highlights-page product-listing-page inverse-navbar" />
      </Helmet>

      <Container>
        <NativeSlider
          className="mb-0 pt-md-5 pb-md-5"
          images={[
            require('./assets/images/antilopenair.jpg'),
            require('./assets/images/Kachel-4-ALGMerch.png'),
            require('./assets/images/Kachel-3-DDMerch.png'),
          ]}
          // keine echten thumbs, bilder werden gestaucht
          // thumbs={[require('./assets/images/slide1.jpg'), require('./assets/images/slide2.jpg')]}
          links={['/product/64723/antilopen-gang-waschhaus-open-air-potsdam-am-14-09-2024', '/products', '/dangerdan']}
          interval={5000}
        />
      </Container>

      <Container className="py-5">
        <h1 className="text-center">{'Ein paar gute Sachen:'}</h1>

        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : // if no products
        products.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="highlights-listing"
            products={products.filter((p) => p.tag_ids.includes(5194))}
            filters="off"
            columnClassName="col-6 col-md-6 col-lg-4"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(Highlights);
