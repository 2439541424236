import React from 'react';

import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'reactstrap';

import { receiveProducts } from '@ttstr/actions';
import { useIntl, ContentrArea } from '@ttstr/components';

import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import FilteredMasonry from './FilteredMasonry';

const DangerDan: React.FC = () => {
  const { products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>Danger Dan – Das ist alles von der Kunstfreiheit gedeckt (Live in Berlin)</title>
          <body className="dangerdan-page inverse-navbar" />
        </Helmet>
        {/* <a href="/tickets?tagId=3642">
          <img
            src={require('./assets/images/dd-tour-2022.png')}
            alt="Danger Dan – Das ist alles von der Kunstfreiheit gedeckt - TOUR 2022"
            className="w-100 img-fluid d-none"
          />
        </a> */}
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md="3" xs="8">
              <img
                src={require('./assets/images/danger-dan-logo.svg')}
                alt="Danger Dan – Das ist alles von der Kunstfreiheit gedeckt - Das unerwartete Klavieralbum"
                className="w-100 img-fluid mb-4"
              />
            </Col>
            <Col md="12" className="text-center d-none">
              <h3>Das ist alles von der Kunstfreiheit gedeckt (Live in Berlin)</h3>
              <h3>OUT NOW</h3>
            </Col>
          </Row>
        </Container>
        <FilteredMasonry
          className="mt-5"
          id="product-listing"
          // type={Maki2020}
          products={products}
          filters="off"
          tagId={3619}
          columnClassName="col-md-6 col-lg-4"
          showSubtitle
          // itemClassName="random-rotation-object"
          // itemImageClassName="scribble-hover-overlay-object"
        />
        <ContentrArea id="shipping-and-refund-main" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(DangerDan);
